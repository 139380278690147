<template>
  <toggle-section
    :is-open="isOpen[getSectionName('coverification', index)]"
    :section="getSectionName('coverification', index)"
    @toggle="toggleTable"
    chevron-position="right"
  >
    <template #titleSection>
      <lf-h3>{{ $t("DEALS.LEXIS_NEXIS.KYB.COMPANY_VERIFICATION") }}</lf-h3>
    </template>
    <template #content>
      <div class="rounded-lg border">
        <div class="p-5 border-b">
          <lf-h3>{{ $t("DEALS.LEXIS_NEXIS.KYB.VERIFIED_INDICATORS") }}</lf-h3>
        </div>
        <div class="p-5">
          <div class="grid grid-cols-2 gap-4">
            <div class="grid grid-cols-1 grid-rows-4 gap-2 pr-4 border-r">
              <div
                v-for="(indicator, indicatorIndex) in Object.entries(
                  business.CompanyVerificationSection?.CompanyVerification
                    .VerifiedIndicators ?? {}
                ).slice(0, 4)"
                :key="indicatorIndex"
              >
                <icon-base
                  icon="x-circle"
                  :icon-color="
                    indicator[1] ? UTIL_COLORS.SUCCESS : UTIL_COLORS.ERROR
                  "
                />
                {{ camelCaseToWords(indicator[0]) }}
              </div>
            </div>
            <div class="grid grid-cols-1 grid-rows-4 gap-2">
              <div
                v-for="(indicator, indicatorIndex) in Object.entries(
                  business.CompanyVerificationSection?.CompanyVerification
                    .VerifiedIndicators ?? {}
                ).slice(4)"
                :key="indicatorIndex"
              >
                <icon-base
                  icon="x-circle"
                  :icon-color="
                    indicator[1] ? UTIL_COLORS.SUCCESS : UTIL_COLORS.ERROR
                  "
                />
                {{ camelCaseToWords(indicator[0]) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </toggle-section>
</template>

<script setup lang="ts">
import type { PropType } from "vue";
import ToggleSection from "@/components/ToggleSection.vue";
import type { Business } from "@/models/commercialData/lexisnexis/KybReport";
import { getSectionName } from "@/helpers/lexisnexis";
import { camelCaseToWords } from "@/helpers/formatting";
import { UTIL_COLORS } from "@/helpers/constants";

defineProps({
  business: {
    type: Object as PropType<Business>,
    required: true
  },
  index: {
    type: Number,
    required: true
  },
  isOpen: {
    type: Object,
    required: true
  }
});

const emit = defineEmits<{
  (eventName: "toggle", key: string): void;
}>();
const toggleTable = (section: string) => {
  emit("toggle", section);
};
</script>
